<template>
  <div class="main">
    <div class="top_select">
      <div>
        <el-select
          v-model="period"
          placeholder="请选择"
          size="small"
          @change="getList"
          :style="{ width: '100px' }"
        >
          <el-option
            v-for="item in periods"
            :key="item.period"
            :label="item.period"
            :value="item.period"
          >
          </el-option>
        </el-select>
        <el-button type="text" icon="Refresh" @click="getList" size="small"
          >平台提取</el-button
        >
      </div>
      <div>
        <el-button
          type="text"
          size="small"
          class="outline"
          @click="dialogFormVisible = true"
          >发票提醒</el-button
        >
      </div>
    </div>
    <div class="bill" v-loading="loading">
      <div
        style="display: flex; justify-content: space-between; margin: 0 10px"
      >
        <div class="bill_type">进</div>
        <div style="color: #3c6ff2; font-size: 14px; cursor: pointer">
          <!-- 查看明细 -->
        </div>
      </div>
      <div class="info">
        <div
          class="info_box"
          style="padding-bottom: 10px; border-bottom: 1px solid #d4eaf8"
        >
          <div class="each_info" style="border-right: 1px solid #eee">
            <div>本期发票数量 (张)</div>
            <p>{{ inSums.count }}</p>
          </div>
          <div class="each_info">
            <div>本期价税合计 (元)</div>
            <p>{{ inSums.sum_total }}</p>
          </div>
        </div>
        <div class="info_box" style="padding-top: 10px">
          <div class="each_info" style="border-right: 1px solid #eee">
            <div>本期金额 (元)</div>
            <p>{{ inSums.sum_amount }}</p>
          </div>
          <div class="each_info">
            <div>本期税额 (元)</div>
            <p>{{ inSums.sum_tax }}</p>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout">专</span>发票<span style="padding: 0 5px">{{
          inSums1.count
        }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums1.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums1.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums1.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">普</span>发票<span
          style="padding: 0 5px"
          >{{ inSums2.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums2.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums2.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums2.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">海关</span
        >发票<span style="padding: 0 5px">{{ inSums3.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums3.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums3.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums3.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout">农</span>发票<span style="padding: 0 5px">{{
          inSums4.count
        }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums4.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums4.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums4.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">其他</span
        >发票<span style="padding: 0 5px">{{ inSums5.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums5.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums5.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums5.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout">电专</span>发票<span style="padding: 0 5px">{{
          inSums6.count
        }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums6.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums6.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums6.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">电普</span
        >发票<span style="padding: 0 5px">{{ inSums7.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ inSums7.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ inSums7.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ inSums7.sum_tax }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bill" v-loading="loading">
      <div
        style="display: flex; justify-content: space-between; margin: 0 10px"
      >
        <div class="bill_type" style="background-color: orange">销</div>
        <div style="color: #3c6ff2; font-size: 14px; cursor: pointer">
          <!-- 查看明细 -->
        </div>
      </div>
      <div class="info">
        <div
          class="info_box"
          style="padding-bottom: 10px; border-bottom: 1px solid #d4eaf8"
        >
          <div class="each_info" style="border-right: 1px solid #eee">
            <div>本期发票数量 (张)</div>
            <p>{{ outSums.count }}</p>
          </div>
          <div class="each_info">
            <div>本期价税合计 (元)</div>
            <p>{{ outSums.sum_total }}</p>
          </div>
        </div>
        <div class="info_box" style="padding-top: 10px">
          <div class="each_info" style="border-right: 1px solid #eee">
            <div>本期金额 (元)</div>
            <p>{{ outSums.sum_amount }}</p>
          </div>
          <div class="each_info">
            <div>本期税额 (元)</div>
            <p>{{ outSums.sum_tax }}</p>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout">专</span>发票<span style="padding: 0 5px">{{
          outSums1.count
        }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ outSums1.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ outSums1.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ outSums1.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">普</span>发票<span
          style="padding: 0 5px"
          >{{ outSums2.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ outSums2.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ outSums2.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ outSums2.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">无</span>发票<span
          style="padding: 0 5px"
          >{{ outSums3.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ outSums3.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ outSums3.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ outSums3.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout">电专</span>发票<span style="padding: 0 5px">{{
          outSums4.count
        }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ outSums4.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ outSums4.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ outSums4.sum_tax }}</div>
          </div>
        </div>
      </div>
      <div class="inout_title">
        <span class="inout" style="background-color: #d56400">电普</span
        >发票<span style="padding: 0 5px">{{ outSums5.count }}</span
        >张
      </div>
      <div class="invoice_box">
        <div class="box_flex">
          <div class="each_box">
            <div>价税合计 (元)</div>
            <div class="amount">{{ outSums5.sum_total }}</div>
          </div>
          <div class="each_box">
            <div>本期金额 (元)</div>
            <div class="amount">{{ outSums5.sum_amount }}</div>
          </div>
          <div class="each_box">
            <div>税额 (元)</div>
            <div class="amount">{{ outSums5.sum_tax }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="发票提醒-预览"
      width="95%"
      destroy-on-close
      center
    >
      <div class="hint">
        <div>【税务温馨提示】</div>
        <div>尊敬的老板，您的企业 “ 「{{ comName }} 」” 的发票情况如下：</div>
        <div>所属月份：{{ period }}</div>
        <div>销项金额(含税)：{{ outSums.sum_total }}元</div>
        <div>销项税额：{{ inSums.sum_total }}元</div>
        <div>——————</div>
        <div>已勾选进项：</div>
        <div>进项金额(含税)：{{ outSums.sum_tax }}元</div>
        <div>进项税额：{{ inSums.sum_tax }}元</div>
        <div>上期留抵税额：0元</div>
        <div>——————</div>
        <div>剩余未勾选进项：</div>
        <div>金额(含税)：</div>
        <div>税额：</div>
        <div>据目前销项进项情况，{{ period }}预计增值税：元</div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
          "
        >
          <div>
            预计增值税时 <span style="color: #3c6ff2">减去 “进项未认证”</span>
          </div>
          <div><el-switch v-model="checked"> </el-switch></div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" type="primary" @click="send"
            >确认发送</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import { qywxFpList } from "@/api/home";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
const comId = ref(null);
const period = ref();
const dialogFormVisible = ref(false);
const periods = ref([]);
const loading = ref(false);
const comName = ref("");
const inSums = ref({}); //增值税
const inSums1 = ref({}); //增值税专用发票
const inSums2 = ref({}); //增值税普通发票
const inSums3 = ref({}); //海关
const inSums4 = ref({}); //农副产品
const inSums5 = ref({}); //其他
const inSums6 = ref({}); //增值税电专
const inSums7 = ref({}); //增值税电普
const inSums8 = ref({}); //本月认证
const outSums = ref({}); //销项
const outSums1 = ref({}); //专票
const outSums2 = ref({}); //普票
const outSums3 = ref({}); //无票
const outSums4 = ref({}); //电专
const outSums5 = ref({}); //电普
const checked = ref(false);
const init = (id, name) => {
  comId.value = id;
  comName.value = name;
  periods.value = proxy.$store.getters["user/periods"];
  period.value = periods.value[0].period;
  getList();
};
const getList = () => {
  loading.value = true;
  qywxFpList({
    comId: comId.value,
    period: period.value,
  }).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      let info = res.data.data;
      inSums.value = info.inSum;
      inSums1.value = info.inSum1;
      inSums2.value = info.inSum2;
      inSums3.value = info.inSum3;
      inSums4.value = info.inSum4;
      inSums5.value = info.inSum5;
      inSums6.value = info.inSum6;
      inSums7.value = info.inSum7;
      inSums8.value = info.inSum8;
      outSums.value = info.outSum;
      outSums1.value = info.outSum1;
      outSums2.value = info.outSum2;
      outSums3.value = info.outSum3;
      outSums4.value = info.outSum4;
      outSums5.value = info.outSum5;
    }
  });
};
//发送
const send = () => {
  wx.invoke(
    "sendChatMessage",
    {
      msgtype: "text", //消息类型，必填
      enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
      text: {
        content:
          "【税务温馨提示】\n尊敬的老板，您的企业“「" +
          comName.value +
          "」”的发票情况如下:\n所属月份：" +
          period.value +
          "\n销项金额(含税)：" +
          outSums.value.sum_total +
          "元\n销项税额：" +
          inSums.value.sum_total +
          "元\n——————\n已勾选进项：\n进项金额(含税)：" +
          outSums.value.sum_tax +
          "元\n进项税额：" +
          inSums.value.sum_tax +
          "元\n上期留抵税额：" +
          inSums.value.sum_tax +
          "元\n——————\n剩余未勾选进项：\n金额(含税)：" +
          inSums.value.sum_tax +
          "元\n税额：" +
          inSums.value.sum_tax +
          "元\n据目前销项进项情况，预计增值税：" +
          inSums.value.sum_tax +
          "元",
      },
    },
    function (res) {
      if (res.err_msg == "sendChatMessage:ok") {
        ElMessage.success("发送成功");
        dialogFormVisible.value = false;
      } else {
      }
    }
  );
};
defineExpose({
  init,
});
</script>
<style scoped lang="scss">
.top_select {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: 10px;
}
.bill {
  margin: 0 10px 15px;
  padding-top: 15px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e1e2e3;
  font-size: 14px;
  position: relative;
}
.inout_title {
  display: flex;
  align-items: center;
  margin: 0 10px;
  margin-top: 15px;
}
.inout {
  line-height: 18px;
  // background-color: #5ca6e5;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  padding: 2px;
  background-color: green;
}
.invoice_box {
  padding: 10px 5px;
  border-bottom: 1px solid #efefef;
  margin: 0 10px;
}
.invoice_box:last-child {
  border-bottom: none;
}
.box_flex {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.each_box {
  width: 33.3%;
  text-align: center;
  border-right: 1px solid #efefef;
}
.each_box:last-child {
  border-right: none;
}
.two_box {
  width: 50%;
}
.amount {
  margin-top: 5px;
}
.hint {
  div {
    font-size: 14px;
    line-height: 24px;
  }
}
.info {
  background-color: #f0f4f9;
  padding: 15px 0;
  border-radius: 4px;
  margin: 15px 10px;
  .info_box {
    width: 96%;
    margin: 0 auto;
    display: flex;
  }
  .each_info {
    width: 50%;
    text-align: center;
    p {
      margin-top: 8px;
    }
  }
}

.bill_type {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: #199bcd;
  color: #fff;
  line-height: 16px;
}
</style>
